import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PageTopHead from '../../components/UI/PageTopHead';
import './Career.css';
import { useFormik } from 'formik';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Apply() {
    const [openings, setOpenings] = useState([]);
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
    const params = useParams();

    const onSubmit = async (values, { resetForm }) => {
        let formData = new FormData();
        if (values.cv) {
            formData.append('candidateCv', values.cv);
        }
        formData.append('candidateName', values.name);
        formData.append('candidateEmail', values.email);
        formData.append('candidateMobile', values.mobile);
        formData.append('link', values.profile);
        formData.append('careerId', params.id);

        try {
            const response = await axios.post('https://web.alcyone.in/api/applyJob', formData, {
                headers: {
                    'Accept': 'application/form-data',
                    'Content-Type': "multipart/form-data",
                }
            });
            if (response?.data?.status === true) {
                setIsSuccessfullySubmitted(true);
                setTimeout(() => {
                    setIsSuccessfullySubmitted(false);
                }, 3000);
                resetForm();
            } else {
                toast.error(response?.data?.message, { autoClose: 2000 });
            }
        } catch (error) {
            toast.error("Submission failed. Please try again.", { autoClose: 2000 });
        }
    };

    const formik = useFormik({
        initialValues: {
            job: '',
            name: '',
            email: '',
            mobile: '',
            profile: '',
            cv: '',
        },
        onSubmit,
    });

    useEffect(() => {
        fetch("https://web.alcyone.in/api/careers").then((res) => {
            res.json().then((resp) => {
                setOpenings(resp.result);
            });
        });
    }, []);

    return (
        <section className='inner_page pb-5'>
            {openings && openings
                .filter(job => job.id === Number(params.id))
                .map((job) => (
                    <div key={job.id}>
                        <PageTopHead pageTitle={`We're Hiring`} description={`${job.title} ___ ${job.location}`} />
                        <Container>
                            <Row>
                                <Col md={7} className="mx-auto mt-4">
                                    <div className='job_box mb-4'>
                                        <h2>{job.title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: job.description }} />
                                        <div className='oj_desc mb-5' dangerouslySetInnerHTML={{ __html: job.job_description }} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7} className="mx-auto mt-4 mb-5">
                                    <form className='apply_form mb-5 bg-light' onSubmit={formik.handleSubmit}>
                                        <h3>Apply for this Job</h3>
                                        <Row>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    name="job"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.job || job.title}
                                                    readOnly
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    placeholder="Your Name"
                                                    name="name"
                                                    maxLength="80"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.name}
                                                    onBlur={formik.handleBlur}
                                                    required
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="email"
                                                    placeholder="Email Address"
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                    required
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    name="mobile"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.mobile}
                                                    required
                                                    maxLength={10}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="text"
                                                    placeholder="Website or LinkedIn Profile"
                                                    name="profile"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.profile}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    type="file"
                                                    name="cv"
                                                    id="cv"
                                                    onChange={(event) => {
                                                        formik.setFieldValue("cv", event.target.files[0]);
                                                    }}
                                                    accept='.pdf'
                                                    required
                                                />
                                                <small role="alert" className='mt-1'>Upload your CV (PDF only).</small>
                                            </Col>
                                            <Col md={12}>
                                                <button type="submit" className='mt-4 btn btn-primary d-flex'>Submit</button>
                                            </Col>
                                        </Row>
                                        {isSuccessfullySubmitted && (
                                            <div className="text-white rounded mt-4 bg-success p-3">
                                                Great! Our HR team will connect with you soon. <b>Be ready for your next dream job!</b>
                                            </div>
                                        )}
                                    </form>
                                </Col>
                            </Row>
                            <ToastContainer />
                        </Container>
                    </div>
                ))
            }
        </section>
    );
}
